import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Contact from '@solid-ui-blocks/CallToAction/Block02'

import ContentImages from '@solid-ui-components/ContentImages'

const DiyComparison = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const images = content['comparison-infographic'].images;

  console.log(content['comparison-infographic'].images);

  return (
    <Layout {...props}>
      <Seo
        title='DIY Comparison'
        description='Discover why investing in a review service can make a significant difference in your online reputation and customer acquisition.'
        siteUrl='/'
        thumbnail={{
          hero: {
            src: '/div-comparison-infographic.jpg'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ContentText
          content={[{
            text: "Review Requests",
            color: "omega",
            variant: "h5",
            space: 1
          },{
            color: "omegaDarker",
            variant: "h2",
            text: "Do-it-yourself vs. SRM",
          },{
            color: "omegaDarker",
            text: "At Simple Review Management, we believe in the importance of customer reviews for the success of your business.",
          },{
            color: "omegaDarker",
            text: "In this graphic, we compare the effectiveness of Do-it-yourself review requests versus utilizing the professional review request service SRM.",
          },{
            color: "omegaDarker",
            text: "Discover why investing in a review service can make a significant difference in your online reputation and customer acquisition.",
          }]}
          sx={{
            maxWidth: '800px',
            marginBottom: '20px',
          }}
        />
        <Divider space='3' />
        <ContentImages
          sx={{
            width: [`100%`],
            maxWidth: '800px'
          }}
          content={{images: [images[0]]}}
          imagePosition='center'
        />
        <Divider space='4' />
        <ContentText
          content={[{
            color: "omegaDarker",
            variant: "h3",
            text: "Why Choose a Professional Review Service?",
          },{
            color: "omegaDarker",
            text: "A professional review service helps you build a strong and positive online reputation, instilling trust and confidence in potential customers.",
          },{
            color: "omegaDarker",
            text: "Increased review quantity and quality positively impact your search engine rankings, making it easier for customers to find your business online.",
          },{
            color: "omegaDarker",
            text: "By leveraging the power of a professional review service, you gain a competitive edge in your industry. A higher number of positive reviews sets you apart from competitors and attracts more customers.",
          }]}
          sx={{
            maxWidth: '800px',
            marginBottom: '20px',
          }}
        /> 
      </Container>
      <Divider space='4' />
      <Contact content={content['cta']} />
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query diyComparison {
    allBlockContent(
      filter: { page: { in: ["site/diy-comparison"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default DiyComparison
